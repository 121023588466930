
import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import LinkArrowSvg from "../../images/link-arrow";
import { Link } from "gatsby";

const DesignResources = () => {
    return (
        <section className="lg:py-[200px] md:py-[120px] py-[80px]">
            <div className="container">
                <h2 className="lg:mb-[30px] mb-[20px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]">Design Resources</h2>
                <p className='lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] lg:mb-[30px] mb-[20px] tracking-[.02em] md:tracking-[.03em] lg:w-[800px] pr-[10px] lg:pr-[0]'>
                    Explore our free design tools and kits to amplify your creative projects. Download now to streamline your design process and bring your vision to life.
                </p>
                <div className='team'>
                    <Link to="/resources/" title='Explore' className="capitalize w-fit font-body text-[12px] lg:text-[15px] leading-[1.2] tracking-[.03em] flex items-center gap-[9px]">
                        EXPLORE ALL  <span className='rotate-45'><LinkArrowSvg size={15.58} fill='#2A2A2A' /></span>
                    </Link>
                </div>
                <div className="flex justify-between mt-[30px] md:mt-[40px] lg:mt-[50px] flex-wrap lg:flex-nowrap">
                    <Link to="/resources/free-charts-icon-set/" className="lg:max-w-[530px] lg:w-[47%] w-[100%] md:mb-[60px] mb-[40px] lg:mb-[0px]">
                        <StaticImage src="../../images/service-resource.webp" alt="resources-1" className="lg:mb-[30px] mb-[20px]" />
                        <h3 className="md:mb-[15px] mb-[10px] md:text-[28px] lg:text-[35px] text-[20px] leading-[27px] md:leading-[40px] lg:leading-[55px] font-heading font-medium">Free Charts Icon Set</h3>
                        <p className="lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] lg:max-w-[458px]">We have a vast range of high quality icons for all kinds of business.</p>
                    </Link>
                    <Link to="/resources/free-landing-page-ui-kit/" className="lg:max-w-[530px] lg:w-[47%] w-[100%]">
                        <StaticImage src="../../images/service-resource-2.webp" alt="resources-1" className="lg:mb-[30px] mb-[20px]" />
                        <h3 className="md:mb-[15px] mb-[10px] md:text-[28px] lg:text-[35px] text-[20px] leading-[27px] md:leading-[40px] lg:leading-[55px] font-heading font-medium">Free Landing Page UI Kit</h3>
                        <p className="lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] lg:max-w-[458px]">We design readymade UI kits for lightning fast implementation.</p>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default DesignResources
