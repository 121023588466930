import React from "react";
import ServiceStaticTabs from "./ServiceStaticTabs";

const OneRoof = () => {
  const oneRoofData = [
    {
      title: "Research",
      desc: `The study of user insights through qualitative and quantitative methods to create delightful digital experiences and increased user satisfaction.`,
      tabs: [
        {
          link: "/user-research/",
          text: "User Research",
        },
        {
          link: "/design-audit/",
          text: "Design Audit ",
        },
        {
          link: "/usability-testing/",
          text: "Usability Testing",
        },
        {
          link: "/ux-consulting/",
          text: "UX Consultation",
        },
        {
          link: "/user-acquisition-consulting/",
          text: "User Acquisition Consultation",
        },
      ],
    },
    {
      title: "Design",
      desc: `The process of crafting creative and functional design solutions for diverse industries to improve user experience and promote brand identity.`,
      tabs: [
        {
          link: "/ui-ux-designing/",
          text: "UI UX Designing",
        },
        {
          link: "/rapid-prototyping/",
          text: "Rapid Prototyping",
        },
        {
          link: "/design-systems/",
          text: "Design System Engineering",
        },
        {
          link: "/branding/",
          text: "Branding",
        },
        {
          link: "/data-visualisation/",
          text: "Dashboards and Data Visualisation",
        },
        {
          link: "/digital-transformation/",
          text: "Digital Transformation",
        },
        {
          link: "/corporate-training-workshop/",
          text: "UI UX Workshops and Training",
        },
      ],
    },
    {
      title: "Build",
      desc: `It involves translating designs and implementing visual elements to create interactive and user-centric digital experiences aligned with business goals.`,
      tabs: [
        {
          link: "/ui-development/",
          text: "UI Development",
        },
        {
          link: "/product-development/",
          text: "Product Development",
        },
        {
          link: "/reactjs-development/",
          text: "ReactJS Development",
        },
        {
          link: "/vuejs-development/",
          text: "VueJS Development",
        },
        {
          link: "/angularjs-development/",
          text: "Angular Development",
        },
        {
          link: "/wordpress-development/",
          text: "WordPress Development",
        },
        {
          link: "/gatsbyjs-development/",
          text: "GatsbyJs Development",
        },
        {
          link: "/shopify-development/",
          text: "Shopify Development",
        },
        {
          link: "/webflow-development/",
          text: "Webflow Development",
        },
        {
          link: "/flutter-development/",
          text: "Flutter Development",
        },
      ],
    },
  ];
  return (
    <section className="bg-[#121212] lg:py-[200px] md:py-[120px] py-[80px] text-white relative w-full overflow-hidden bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top">
      <div className="container z-[1] relative flex flex-col lg:gap-y-[180px] md:gap-y-[120px] gap-y-[80px]">
        {oneRoofData.map((data, index) => (
          <div key={index}>
            <div className="lg:mb-[40px] md:mb-[30px] mb-[20px]">
              <h2 className="lg:mb-[10px] mb-[8px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] w-full max-w-[715px]">
                {data.title}
              </h2>
              <p className="lg:text-[18px] md:text-[16px] text-[15px] font-400 leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] md:w-[668px] lg:w-[800px] pr-[10px] md:pr-[0] text-white">
                {data.desc}
              </p>
            </div>
            <ServiceStaticTabs tabs={data.tabs} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default OneRoof;
