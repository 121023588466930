import React from 'react';
import ServiceStaticTabs from './ServiceStaticTabs';

const DomainDetail = () => {
    const tabs = [
        {
          link: "/saas-design-agency/",
          text: "SaaS Design",
        },
        {
          link: "/ecommerce-design-agency/",
          text: "eCommerce Design",
        },
        {
            link: "/mobile-app-design-agency/",
            text: "Mobile App Design",
        },
        {
            link: "/website-design-agency/",
            text: "Website Design",
        },
        {
            link: "/mobile-app-development-company/",
            text: "Mobile App Development",
        },
        {
            link: "/web-development-company/",
            text: "Web Development",
        }
    ];
    const tabs2 = [
        {
          link: "/edtech-design-agency/",
          text: "EdTech",
        },
        {
            link: "/healthcare-design-agency/",
            text: "Healthcare",
        },
        {
            link: "/fintech-design-agency/",
            text: "FinTech",
        },
        {
            link: "/logistics-design-agency/",
            text: "Logistics",
        },
        {
            link: "/banking-design-agency/",
            text: "Banking",
        },
        {
            link: "/e-governance-design-agency/",
            text: "eGovernance",
        },
        {
            link: "/telecom-design-agency/",
            text: "Telecom",
        },
        {
            link: "/iot-design-agency/",
            text: "IOT",
        },
        {
            link: "/ai-design-agency/",
            text: "AI Design",
        }
    ];
    return (
        <section className="bg-[#FAFBFB] lg:py-[200px] md:py-[120px] py-[80px]">
            <div className="container z-[1] relative flex flex-col lg:gap-y-[180px] md:gap-y-[120px] gap-y-[80px]">
                <div>
                    <div className='lg:mb-[50px] md:mb-[80px] mb-[40px]'>
                        <h2 className='lg:mb-[30px] mb-[20px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]'>Our product-focused Solutions</h2>
                        <p className='lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] lg:w-[800px] pr-[10px] lg:pr-[0]'>
                            We have built several product categories and gained in-depth knowledge of their fields. As an experienced UI UX design agency, we craft tailor-made solutions and deliver designs that exceed your expectations.  </p>
                    </div>
                    <ServiceStaticTabs
                        tabs={tabs}
                        dark
                    />
                </div>
                <div>
                    <div className='lg:mb-[50px] md:mb-[80px] mb-[40px]'>
                        <h2 className='lg:mb-[30px] mb-[20px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]'>Our Industry Expertise</h2>
                        <p className='lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] lg:w-[800px] pr-[10px] lg:pr-[0]'>
                        We deliver the best solutions for your company's needs. With our specialized approach, you will have access to our expert designers, who are proficient in a variety of industries, to help you enhance your projects.</p>
                    </div>
                    <ServiceStaticTabs
                        tabs={tabs2}
                        dark
                    />
                </div>
            </div>
        </section>
    )
}

export default DomainDetail
