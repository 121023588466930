
import React from 'react';
import ServiceStaticTabs from './ServiceStaticTabs';

const HireComponent = () => {
    const tabs = [
        {
          link: '/hire-ui-ux-designers/',
          text: 'UI UX Designer'
        },
        {
          link: '/hire-saas-designers/',
          text: 'SaaS Designer'
        },
        {
          link: '/hire-hotjar-experts/',
          text: 'Hotjar Research Experts'
        },
        {
          link: '/hire-conversion-rate-optimisation-experts/',
          text: 'CRO Experts'
        },
        {
          link: '/hire-ux-researchers/',
          text: 'UX Researchers'
        },
        {
          link: '/hire-web-designers/',
          text: 'Web Designer'
        },
        {
          link: '/hire-mobile-app-designers/',
          text: 'Mobile App Designer'
        },
        {
          link: '/hire-webflow-developers/',
          text: 'Webflow Developer'
        },
        {
          link: '/hire-gatsbyjs-developers/',
          text: 'GatsbyJs Developer'
        },
        {
          link: '/hire-reactjs-developers/',
          text: 'ReactJs Developer'
        },
        {
          link: '/hire-angularjs-developers/',
          text: 'AngularJs Developer'
        },
        {
          link: '/hire-vuejs-developers/',
          text: 'VueJs Developer'
        },
        {
          link: '/hire-nextjs-developers/',
          text: 'NextJs Developer'
        },
        {
          link: '/hire-nuxtjs-developers/',
          text: 'NuxtJs Developer'
        }
      ];
    return (
        <section className="lg:py-[200px] md:py-[120px] py-[80px] bg-[#121212] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top">
            <div className="container">
                <h2 className="lg:mb-[30px] mb-[20px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]">Get Goal-Oriented Designer</h2>
                <p className='lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] lg:mb-[60px] md:mb-[40px] mb-[20px] tracking-[.02em] md:tracking-[.03em] lg:w-[800px] pr-[10px] lg:pr-[0]'>
                  We ensure to provide the perfect match for your business unique requirements. With our tailored approach, you can access to our skilled designers proficient in diverse areas to elevate your projects.
                </p>
                <ServiceStaticTabs tabs={tabs} />
            </div>
        </section>
    )
}

export default HireComponent
