import React, { } from "react";
import { Link } from "gatsby";

const ServiceStaticTabs = ({ tabs, dark }) => {
  return (
    <section
      className={`lg:py-[30px] py-[20px relative transparent ${dark ? '' : 'transparent text-white'}`}
    >
      <div
        className={`tabs-list flex items-center pr-[30px] flex-wrap !ml-[0] lg:pr-[30px] gap-[20px] `}
      >
        {tabs.map((item, index) => (
          <div key={index} className="relative">
            <Link
              to={item.link}
              className={`w-full rounded-[30px] ${dark ? 'dark' : ''} relative lg:py-[10px] md:py-[8px] py-[6px] md:px-[20px] px-[12px] cursor-pointer select-none overflow-hidden min-w-fit`}
            >
              <p className="text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] select-none whitespace-nowrap">
                {item.text}
              </p>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceStaticTabs;
