import React from "react";
import Banner from "../components/Banner";
import DomainDetail from "../components/service/DomainCovered";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Clients from "../components/service/Clients";
import Cta from "../components/service/Cta";
import OneRoof from "../components/service/OneRoof";
import DesignResources from "../components/service/DesignResources";
import Layout from "../components/layout";
import HireComponent from "../components/service/HireComponent";

const ServiceMainPage = () => {
  const banner = {
    title: `<span class="h1-span">Excel With Our </span><span class="h1-span"> Design Services</span>`,
    mobileTitle: "Excel With Our Design Services",
    content:
      "We are experts in delivering strategic design services that capture your business essence to offer innovative solutions and help you stand out in the competitive landscape.",
  };
  const clientsTitle = "Companies who excelled with our UX Testing";
  const cta = {
    title: "Curious to know more about our services?",
  };
  return (
    <Layout>
      <div className="service-page">
        <Banner content={banner} page={"services"} />
        <OneRoof />
        <DomainDetail />
        <HireComponent />
        <Clients title={clientsTitle} />
        <Cta data={cta} />
        <DesignResources />
        {/* <KnowMoreSection /> */}
      </div>
    </Layout>
  );
};

export default ServiceMainPage;

export const Head = () => (
  <Seo
    title="Holistic UI UX Services | Tailor-made Solutions | Octet"
    description="We deliver tailor-made UI UX solutions for your digital products with our services in strategy, research and build to provide exceptional digital experiences."
  />
);
